/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Main Tailwind file for injecting utilities.
/* ----------------------------------------------------------------------------------------------------- */
@tailwind utilities;

//for Luminovo design system
.h1 {
    font-size: 20px;
    font-weight: 500;
    font-family: 'Poppins';
    line-height: 24px;
    color: #2A3147;
}
.h2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
}
.h3 {
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins';
    line-height: 20px;
    color: #2A3147;
}
.h4 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}
.h5 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
}

.body1 {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Noto Sans';
    line-height: 20px;
}

.body2 {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Noto Sans';
    line-height: 20px;
}

.body-small {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
}

.body-semibold {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}

.subtitle1 {
    font-size: 12px;
    font-weight: 400;
    font-family: 'Noto Sans';
    line-height: 16px;
    color: #2A3147;
}

.subtitle1 {
    font-size: 12px;
    font-weight: 500;
    font-family: 'Noto Sans';
    line-height: 16px;
    color: #2A3147;
}

.caption {
    font-size: 10px;
    font-weight: 400;
    font-family: 'Noto Sans';
    line-height: 12px;
    color: #2A3147;
}

//for academy rendered markdown
markdown {
    h1 {
        font-size: 2em!important;
        margin: 0.67em 0;
        font-weight: bolder
    }
    h2 {
        font-size: 1.5em!important;
        margin: 0.83em 0;
        font-weight: bolder
    }
    h3 {
        font-size: 1.17em!important;
        margin: 1em 0;
        font-weight: bolder
    }
    h4 {
        font-size: 1em!important;
        margin: 1.33em 0;
        font-weight: bolder
    }
    h5 {
        font-size: .83em!important;
        margin: 1.67em 0;
        font-weight: bolder
    }
    h6 {
        font-size: .67em!important;
        margin: 2.33em 0;
        font-weight: bolder
    }
}